<template>
    <Alert v-for="(a, index) in alert" :key="a" :data="a" @closeAlert="closeAlert(index)" />
    <TopNavigation step="0" title="Document Preview" icon="searchengin" hideSecondNavbar="true" />

    <div class="contentDiv">
      <div class="row">
        <!-- <span class="toolboxBtn" @click="toggleToolbox">
          <fa :icon="toolboxBtnIcon" class="text-white" />
        </span> -->
        <span v-if="!showToolbox" class="toolboxBtnClose" @click="toggleToolbox">
          <fa icon="chevron-right" class="text-white" />
        </span>

        <span v-if="showToolbox" class="toolboxBtnOpen" @click="toggleToolbox">
          <fa icon="chevron-left" class="text-white" style="padding-left: 2px;" />
        </span>
        
        <!-- Toolbox -->
        <div v-if="showToolbox" class="bottom-0 p-0 vh-100 pe-1 toolbarViewFloat" style="z-index: 2">
            <div id="accordion" class="pt-1 ps-1">
                <div class="card mb-2">
                    <div class="card-header px-2 pt-1 text-center">
                        <table width="100%" align="center" border="0">
                          <tr>
                            <td width="50%">
                              <button class="btn btn-secondary" style="width: 100%" @click="router.push('/project')">
                                  <fa icon="folder" class="me-1" />Folder
                              </button>
                            </td>
                            <td  width="50%">
                              <button class="btn btn-secondary" style="width: 100%" @click="router.push('/project/' + route.params.projectId)">
                                  <fa icon="chart-line" class="me-1" v-cloak/>Overview
                              </button>
                            </td>
                          </tr>
                        </table>
                    </div>

                    <div id="left1" class="collapse show" >
                        <div class="card-body">
                            <div class="py-2">
                                <table border="0" align="center">
                                    <tr v-if="editPage === false">
                                        <td v-if="page === 1" class="pe-2 text-secondary"><fa icon="angle-double-left" :disabled="page === 1" /></td>
                                        <td v-if="page !== 1" class="pe-2 isLink" @click="navigatePage('first'); updPageNum(page)"><fa icon="angle-double-left" /></td>
                                        <td v-if="page <= 1" class="px-2 text-secondary"><fa icon="angle-left" /></td>
                                        <td v-if="page > 1" class="px-2 isLink" @click="navigatePage('prev'); updPageNum(page)"><fa icon="angle-left" /></td>
                                        <td><span class="isLinkPrimary" @click="editPage = true">{{ goToPage.toLocaleString() }}</span> / {{ pageCount.toLocaleString() }}</td>
                                        <td v-if="page >= pageCount" class="px-2 text-secondary"><fa icon="angle-right" /></td>
                                        <td v-if="page < pageCount" class="px-2 isLink" @click="navigatePage('next'); updPageNum(page)"><fa icon="angle-right" /></td>
                                        <td v-if="page === pageCount" class="ps-2 text-secondary"><fa icon="angle-double-right" /></td>
                                        <td v-if="page !== pageCount" class="ps-2 isLink" @click="navigatePage('last'); updPageNum(page)"><fa icon="angle-double-right" /></td>
                                    </tr>
                                    <tr v-else>
                                        <td>
                                            <div class="d-flex justify-content-center">
                                                <span class="input-group input-group-xs" style="width: 150px">
                                                    <span class="input-group-text">Go to page</span>
                                                    <input type="text" v-model="goToPage" autofocus class="form-control form-control-xs" @input="navigatePage(); updPageNum(page)" @focusout="editPage = false" >
                                                </span>
                                                <span class="ps-2 pt-1">/ {{ pageCount.toLocaleString() }}</span>
                                            </div>
                                        </td>
                                </tr>
                                </table>
                            </div>

                            <div class="pb-2">
                                <table align="center" border="0">
                                    <tr> 
                                    <td  width="50px" class="px-2 text-end isLink" @click="zoomOut"><fa icon="minus"></fa></td>
                                    <td v-if="scale === 1"  width="70px" class="pe-2 text-center" @click="zoomDefault">{{Math.floor(scale*100)}}%</td>
                                    <td v-else  width="70px" class="pe-2 isLink text-center" @click="zoomDefault">{{Math.floor(scale*100)}}%</td>
                                    <td  width="50px" class="ps-2 isLink pe-1" @click="zoomIn"><fa icon="plus"></fa></td>
                                    </tr>
                                </table>
                            </div>
                            <!-- <div class="bg-danger text-white">browserWidth: {{ browserWidth }}</div>
                            <div class="bg-danger text-white">scale: {{ scale }} </div>
                            <div class="bg-danger text-white" v-if="doc">spageWidth: {{ doc.jFile.pageWidth }}</div> -->
                        </div>
                    </div>
                </div>

                <div v-if="doc" class="card mb-2">
                    <div class="card-header">
                        <a class="collapsed btn" data-bs-toggle="collapse" href="#left3">Document Information</a>
                    </div>

                    <div id="left3" class="collapse show" >
                        <div class="card-body pb-0 px-2">
                          
                            <!-- Method-2 -->
                            <table class="table table-borderless table-sm" border="0">
                                <tbody>
                                    <tr>
                                        <td colSpan="2">
                                            <span v-if="docStatus !== ''"><DocStatus :status="docStatus" :shred="doc.shred" class="ms-0 ps-0 me-2" /></span>
                                            <Popper class="popperDark" arrow hover :content="docName">
                                                <span>{{ (docName.length > 25 ? docName.substring(0, 25) + '...' : docName) }}</span>
                                            </Popper>
                                        </td>
                                    </tr>
                                    <tr v-if="doc.dateModify">
                                      <td colSpan="2" class="lblTiny text-end pb-2">
                                          <span class="lblTiny mt-0">Modified </span>
                                          <Popper class="popperDark" arrow hover :content="'Created ' + func.getDateTimeDiff(func.convDateTimeFormat(doc.dateCreate), new Date())">
                                              <span v-if="doc.dateModify === '0'" class="isTooltipsLabel lblTiny mt-0 fst-italic">{{ func.getDateTimeDiff(func.convDateTimeFormat(doc.dateCreate), new Date()) }}</span>
                                              <span v-else class="isTooltipsLabel lblTiny mt-0 fst-italic">{{ func.getDateTimeDiff(func.convDateTimeFormat(doc.dateModify), new Date()) }}</span>
                                          </Popper>
                                      </td>
                                    </tr>

                                    <tr><td colSpan="2" class="lblTiny">Signee(s)</td></tr>

                                    <tr v-if="docSignee && docSignee.length === 0"><td colSpan="2">N/A</td></tr>

                                    <tr v-for="s in docSignee.slice(0, 5)" :key="s">
                                        <td width="45px" class="align-middle">
                                            <ImgAvatar :isUser="s.isUser" :id="s.jAvatar.userId" displayName="" :isVerified="s.isVerified" :isActive="s.status" :privilege="s.privilege" width="35" height="35" />
                                            <!-- <Avatar :isUser="s.isUser" :avatar="s.jAvatar.imgBase64" :isVerified="s.isVerified" :privilege="s.privilege" displayName="" :isActive="s.status" width="35" height="35" class="ps-2"/> -->
                                        </td>
                                        <td>
                                            <div>{{s.signeeName}}</div>
                                            <div class="lblTiny">
                                                <span v-if="s.signingStatus === 'PENDINGSIGN'" class="fw-bold">PENDING</span>
                                                <span v-else class="fw-bold" :class="s.signingStatus === 'REJECTED' ? 'text-danger' : ''">{{s.signingStatus}}</span>
                                                <span v-if="s.privilege !== 'SIGNEE'" class="float-end">{{s.privilege}}</span>
                                            </div>
                                            <div v-if="s.comment" class="lblTiny">{{s.comment}}</div>
                                        </td>
                                    </tr>

                                    <tr v-for="s1 in docSignee.slice(5)" :key="s1">
                                        <td width="45px" class="align-middle">
                                            <ImgAvatar :isUser="s1.isUser" :id="s1.jAvatar.userId" displayName="" :isVerified="s1.isVerified" :isActive="s1.status" privilege="" width="35" height="35" />
                                            <!-- <Avatar :isUser="s1.isUser" :avatar="s1.jAvatar.imgBase64" :isVerified="s1.isVerified" :privilege="s1.privilege" displayName="" :isActive="s1.status" width="35" height="35" class="ps-2"/> -->
                                        </td>
                                        <td>
                                            <div>{{s1.signeeName}}</div>
                                            <div class="lblTiny">
                                                <span v-if="s1.signingStatus === 'PENDINGSIGN'">PENDING</span>
                                                <span v-else :class="s.signingStatus === 'REJECTED' ? 'text-danger' : ''">{{s1.signingStatus}}</span>
                                                <span v-if="s1.privilege !== 'SIGNEE'" class="float-end">{{s1.privilege}}</span>
                                            </div>
                                        </td>
                                    </tr>

                                    <tr v-if="docSignee.length > 5 && !showMoreSignee">
                                            <td colSpan="2" class="text-end lblTiny" @click="showMoreSignee = !showMoreSignee">+ {{docSignee.length - 5}} more</td>
                                        </tr>
                                        <tr v-if="docSignee.length > 5 && showMoreSignee">
                                            <td colSpan="2" class="text-end lblTiny" @click="showMoreSignee = !showMoreSignee">- {{docSignee.length - 5}} less</td>
                                        </tr>

                                    <tr v-if="doc.remark">
                                        <td colSpan="2" class="pt-2">
                                            <div><fa icon="clipboard" size="lg" class="text-brown me-2" /><span class="lblTiny">Public editor note to signee(s)</span></div>
                                            <div>{{ doc.remark }}</div>
                                        </td>
                                    </tr>

                                    <tr v-if="doc.note && (projPrivilege === 'OWNER' || projPrivilege === 'EDITOR')">
                                        <td colSpan="2" class="pt-2">
                                            <div><fa icon="note-sticky" size="lg" class="text-brown me-2" /><span class="lblTiny">Private note to editor(s) only</span></div>
                                            <div>{{ doc.note }}</div>
                                        </td>
                                    </tr>

                                </tbody>
                            </table>

                            <div v-if="doc.jFile.isEncrypted === true || doc.jFile.totalOldSignature > 0" class="mt-2 p-2 mb-3 border shadow-sm" style="border-radius: 5px">
                                <div><fa icon="circle-info" size="lg" /> Information</div>
                                <div class="lblTiny">
                                  <!-- isEncrypted: {{ doc.jFile.isEncrypted }} | totalOldSignature: {{ doc.jFile.totalOldSignature }} -->
                                  <span v-if="doc.jFile.isEncrypted === true && doc.jFile.totalOldSignature > 0">
                                    This document is encrypted and has {{ doc.jFile.totalOldSignature }} existing signature{{ doc.jFile.totalOldSignature > 1 ? 's' : '' }}
                                  </span>
                                  <span v-else-if="doc.jFile.isEncrypted === true">
                                    This document is encrypted
                                  </span>
                                  <span v-else-if="doc.jFile.totalOldSignature > 0">
                                    This document has {{ doc.jFile.totalOldSignature }} existing signature{{ doc.jFile.totalOldSignature > 1 ? 's' : '' }}
                                  </span>
                                
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

            </div> 

        </div><!-- End of Toolbox -->
        
        <div :class="contentClass">
          <div class="vh-100 gx-0">

            <div v-if="isPdfLoaded === false" class="text-center text-info" style="width: 100%; position: fixed; z-index: 1000; top: 100px; font-size: 14px">
                <fa icon="spinner" size="lg" class="me-2" />Loading...
            </div>
            
            <div style="position: relative; width: auto; height: 100%; margin-top: 0px; overflow: auto; top: 0px" class="pdfBg">
                <div v-if="doc && doc.jFile.isEncrypted !== true" id="pdfViewBoxContainer" ref="pdfSignBoxContainer" :style="'width:'+Math.ceil(pdfWidth*scale)+'px; height: '+Math.ceil(pdfHeight*scale)+'px; position: absolute; right: 0px;'">
                </div>
                <canvas v-if="doc && doc.jFile.isEncrypted !== true" id="pdf-view-canvas" style="position: absolute; right: 0px"></canvas>
                <div v-if="doc && doc.jFile.isEncrypted !== true" id="pdf-view-annotation-layer" style="position: absolute; right: 0px"></div>

                <div v-if="doc && doc.jFile.isEncrypted === true" class="row mt-4">
                      <div class="col-5 col-lg-4 mb-2 text-end">
                          <fa icon="triangle-exclamation" style="font-size: 120px"/>
                      </div>
                      <div class="col-7 col-lg-8 mb-2">
                          <div class="fw-bold mb-3" style="font-size: 20px">Encrypted document</div>
                          <div>Sorry, encrypted PDF is not supported at the moment.</div>
                      </div>
                  </div>
            </div>
            
          </div>
        </div>
      </div>
    </div>  

</template>

<script>
import TopNavigation from '@/components/TopNavigation.vue'
import Alert from '@/components/Alert.vue'
import { ref, inject, onMounted, watch, onBeforeUnmount } from 'vue'
import { degrees, PDFDocument, rgb, StandardFonts } from 'pdf-lib'
import { useStore } from '@/stores/store'
import { useRoute, useRouter } from 'vue-router'
import DocStatus from '@/components/DocStatus.vue'
import Popper from 'vue3-popper'
import funcs from '@/functions/function'
// import Avatar from '@/components/Avatar.vue'
import ImgAvatar from '@/components/ImgAvatar.vue'
import UserDisplayName from '@/components/UserDisplayName.vue'

let pdfjsDoc = null // for pdfjslib

export default {
  name: 'PdfView',
  components: { Alert, TopNavigation, degrees, PDFDocument, rgb, StandardFonts, DocStatus, Popper, /* Avatar,  */ImgAvatar, UserDisplayName },
  setup () {
      const url = ref(null)
      const urlEncode = ref(null)
      
      const axios = inject('axios')
      const route = useRoute()
      const router = useRouter()
      const store = useStore()
      const { getTotalPage, getUserId } = useStore()
      const func = funcs
      const alert = ref([])

      const pdfWidth = ref(0)
      const uiRatio = ref(0) // ratio between UI width and PDF actual width. 

      const editPage = ref(false)
      const page = ref(1)
      const pageCount = ref(1)
      const goToPage = ref(1)
      const randKey = ref(1)
      const isPdfLoaded = ref(false)
      const scale = ref(1)
      
      const doc = ref(null)
      const docName = ref(null)
      const docStatus = ref(null)
      const docSignee = ref([])
      const projName = ref(null)
      const projPrivilege = ref(null)
      const showToolbox = ref(true)
      const showMoreSignee = ref(false)
      const contentClass = ref('col-12 p-0')
      const toolboxBtnIcon = ref('chevron-left')
      const curPage = ref(1) // update tally to page

      const browserWidth = ref(0) // browser width
      const docWidth = ref(0) //pt


      watch([url], async () => {
        clearAnnotation()
        readDoc(url.value)

      })

      watch([page, scale], async ([newPage, newScale], [oldPage, oldScale]) => {
          // console.info('---- watch page/scale')
          clearAnnotation()
          readPage(page.value, scale.value)
      })

      const closeAlert = (index) => {
            alert.value.splice(index, 1)
        }

      const readDoc = async (url) => {
        // console.info('- - readDoc')
        isPdfLoaded.value = false

        const existingPdfBytes = await fetch(url, { credentials: 'include' }).then(res => res.arrayBuffer())
        const pdfDoc = await PDFDocument.load(existingPdfBytes, { ignoreEncryption: true })
        // const pdfDoc = await PDFDocument.load(existingPdfBytes)
        const pdfVal =  await pdfDoc.saveAsBase64({ dataUri: true })

        /* WORKING: pass in new URL method
        const existingPdfBytes = await fetch(url, { credentials: 'include' }).then(res => res.arrayBuffer())
        const blob = new Blob([existingPdfBytes], { type: "application/pdf" })
        if (window.navigator['msSaveOrOpenBlob']) {
            window.navigator['msSaveBlob'](blob, filename)
        }
        else {
            const newurl =  window.URL.createObjectURL(blob)
            console.info('newurl', newurl)

            urlEncode.value = Pdfvuer.createLoadingTask(newurl)
            urlEncode.value.then(pdf => {
                pageCount.value = pdf.numPages
                isPdfLoaded.value = true
            })
        } */
        
        /* ORIG 
        const loadingTask = pdfjsLib.getDocument(pdfVal)
        // const loadingTask = pdfjsLib.getDocument({url: pdfVal, password: xx})
        loadingTask.promise.then((doc, reason) => {
            pdfjsDoc = doc
            
            pageCount.value = doc.numPages
            readPage(page.value, scale.value)
            isPdfLoaded.value = true
        }) */

        const loadingTask = pdfjsLib.getDocument(pdfVal)
        // const loadingTask = pdfjsLib.getDocument({ url: pdfVal, password: ''})
        loadingTask.promise.then((doc) => {
            pdfjsDoc = doc
            
            pageCount.value = doc.numPages
            readPage(page.value, scale.value)
            isPdfLoaded.value = true

        },(error) => {
            isPdfLoaded.value = true
            /* alert.value.push({
                class: "info",
                title: "INFO",
                message: "The document cannot open due to encrypted."
            }) */

            if (error.name === 'PasswordException') {
              console.info('The PDF is password-protected and no password was provided.')
            } else {
              console.info('ERROR loading the PDF:', error)
            }
        })

    }

    const readPage = async (pageNum, pdfScale) => {

        if(pdfjsDoc) 
        {
            const page = await pdfjsDoc.getPage(pageNum)
            // console.info('page', page)
            const viewport = page.getViewport({ scale: pdfScale * 1.33333 })

            const containerDiv = document.getElementById('pdfViewBoxContainer')
            containerDiv.style.width = viewport.width + 'px'
            containerDiv.style.height = viewport.height + 'px'

            console.info('canvas viewport.width:', viewport.width)

            const canvas = document.getElementById('pdf-view-canvas')
            const context = canvas.getContext('2d')
            const resolution = window.devicePixelRatio || 1

            // console.info('canvas: pdfBoxContainer', canvas)
            canvas.width = Math.floor(viewport.width) * resolution
            canvas.height = Math.floor(viewport.height) * resolution
            canvas.style.width = Math.floor(viewport.width) + 'px'
            canvas.style.height = Math.floor(viewport.height) + 'px'

            context.scale(resolution, resolution)

            const transform = [1, 0, 0, 1, 0, 0]

            // Render PDF page into canvas context
            const renderContext = {
                canvasContext: context,
                transform,
                viewport,
            }
            page.render(renderContext)
            // const opList = await page.getOperatorList()

            const element = document.getElementById('pdf-view-annotation-layer')
            element.width = Math.floor(viewport.width) * resolution
            element.height = Math.floor(viewport.height) * resolution
            element.style.width = Math.floor(viewport.width) + 'px'
            element.style.height = Math.floor(viewport.height) + 'px'
            // element.style.border = '2px solid red'
            
            const annots = await page.getAnnotations()
            for (const annot of annots) {
                if(annot && annot.subtype === 'Link') {
                    renderAnnotationLink(annot, viewport, resolution, element)
                } 
            }
        }
    }

    const renderAnnotationLink = (annotation, viewport, resolution, elAnnoLayer) => {
        const scale = viewport.scale

        const [x0, y0, x1, y1] = annotation.rect
        const yDiff = (y1 - y0) * scale
        const x = x0 * scale
        const y = Math.floor(viewport.height) - ( y0 * scale) - yDiff
        const width = (x1 - x0) * scale
        const height = (y1 - y0) * scale

        var lnk = window.document.createElement('a')
        lnk.href = annotation.url
        lnk.style.position = 'absolute'
        lnk.style.left = `${x}px`
        lnk.style.top = `${y}px`
        lnk.style.width = `${width}px`
        lnk.style.height = `${height}px`
        // lnk.style.border = '1px solid red'
        // lnk.style.backgroundColor = 'rgba(255, 255, 0, 0.5)'
        // lnk.style.pointerEvents = 'none'
        // lnk.style.innerText = annotation.title

        elAnnoLayer.appendChild(lnk)
    }

    const clearAnnotation = async () => {
        const container = document.getElementById('pdf-view-canvas')
        if (container !== null) {
          while (container.firstChild) {
              container.removeChild(container.firstChild)
          }
        }

        const container2 = document.getElementById('pdf-view-annotation-layer')
        if (container2 !== null) {
          while (container2.firstChild) {
              container2.removeChild(container2.firstChild)
          }
        }
    }

    const zoomIn = () => {
        // console.info('zoomIn +')
        scale.value = parseFloat((scale.value + 0.1).toFixed(1))
        randKey.value = Math.floor(Math.random() * 1000)

    }

    const zoomOut = async () => {
        // console.info('zoomOut -')
        scale.value = parseFloat((scale.value - 0.1).toFixed(1))
        randKey.value = Math.floor(Math.random() * 1000)
    }

    const zoomDefault = async () => {
        // console.info('zoomDef')
        scale.value = 1
        randKey.value = Math.floor(Math.random() * 1000)
    }

    const navigatePage = (opt) => {
        if (opt === 'prev') {
            page.value = page.value - 1
            goToPage.value = page.value

        } else if (opt === 'next') {
            page.value++
            goToPage.value = page.value

        } else if (opt === 'first') {
            page.value = 1
            goToPage.value = page.value

        } else if (opt === 'last') {
            page.value = pageCount.value
            goToPage.value = page.value     

        } else if (opt !== '' && opt > 0) {
            page.value = opt * 1
            goToPage.value = opt * 1  

        } else {
            goToPage.value = goToPage.value * 1
            if (goToPage.value > 0 && goToPage.value <= pageCount.value) {
                page.value = goToPage.value

            } else { 
                page.value = 1
                goToPage.value = page.value
            }
        }
        randKey.value = Math.floor(Math.random() * 1000)
    }

      const updPageNum = (page1) => {
        curPage.value = page1

      }

      const toggleToolbox = async () => {
        localStorage.setItem('hasClickViewToolbox', true)
        showToolbox.value = !showToolbox.value
        localStorage.setItem('leftViewDrawer', showToolbox.value)
        // location.reload() // Need to detect window size

      }

      const getDocument = async (id) => {
        axios.get( '/signon/' + route.params.projectId + '/' + id)
          .then((res) => {
          
              if (res.data.status === 1) {
                // console.info('doc', JSON.stringify(res.data.data))
                doc.value = res.data.data
                url.value = res.data.data.jFile.viewUrl
                docName.value = res.data.data.jFile.name.replace('.pdf', '')
                docStatus.value = res.data.data.status
                docWidth.value = res.data.data.jFile.pageWidth
                
                if (doc.value.jSignee) {
                    // Get OWNER first
                    doc.value.jSignee.forEach((item, idx) => {
                        if (item.privilege === 'OWNER' ) {
                            docSignee.value.push(item)
                        }

                    })

                    // Get EDITOR first
                    doc.value.jSignee.forEach((item, idx) => {
                        if (item.privilege === 'EDITOR' ) {
                            docSignee.value.push(item)
                        }

                    })
                }

                if (doc.value.jSignee) {
                  // Get non-creator
                  doc.value.jSignee.forEach((item, idx) => {
                      if (item.privilege !== 'OWNER' && item.privilege !== 'EDITOR' ) {
                          docSignee.value.push(item)
                      }
                  })
                }

              } else {
                  func.addLog('pdfView', 'getDocument', res)

                  if (res && res.data !== null && res.data !== undefined) {
                      alert.value.push({
                        class: "danger",
                        title: "ERROR",
                        message: func.getError(res.data)
                      })
                  }
              }

        })
        .catch((error) => {
            func.addLog('pdfView', 'getDocument  - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)
        })
      }

      const getProject = () => {
        return axios.get( '/signon/' + route.params.projectId).then((res) => {
          if (res.data.status === 1) {
              projName.value = res.data.data.folderName
              projPrivilege.value = res.data.data.privilege

          } else {
              func.addLog('pdfView', 'getProject', res)

              if (res && res.data !== null && res.data !== undefined) {
                  alert.value.push({
                    class: "danger",
                    title: "ERROR",
                    message: func.getError(res.data)
                  })
              }

          }

        })
        .catch((error) => {
            func.addLog('pdfView', 'getProject  - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)
        })

      }

      const iniScale = async () => {
        console.info('iniScale', 'browserWidth:', browserWidth.value, docWidth.value)

        if (browserWidth.value <= 380) {
          // cater for mobile phone
          scale.value = 0.4

        } else if (browserWidth.value <= 428) {
          // cater for mobile phone
          scale.value = 0.5

        } else if (browserWidth.value <= 820) {
          // cater for tablet
          scale.value = 0.7

        } else {
          if (docWidth.value) {
            let pdfWidth = docWidth.value * 1.33333.toFixed(5) * 1 //convert docWidth from pt to px
            let pdfBox = browserWidth.value - 255 // deduct toolbar

            let newScale = scale.value
            let newPdfWidth = pdfWidth

            for(var tmpScale = 1; tmpScale <= 2; tmpScale+=0.1) {
                tmpScale = tmpScale.toFixed(1) * 1
                newPdfWidth = pdfWidth * tmpScale

                if (newPdfWidth <= pdfBox) {
                    newScale = tmpScale
                }
            }

            scale.value = newScale
            console.info('final scale: ' + newScale, 'pdfWidth', pdfWidth)

          } 
        }
      }

      onMounted(() => {
        browserWidth.value = window.innerWidth

        // First time login
        if (route.query.fl === true || route.query.fl === 'true') {
          alert.value.push({
            class: "success",
            title: "SUCCESS",
            message: "Welcome to SignOn."
          })
        }

        // Not first time login
        if (route.query.fl === false || route.query.fl === 'false') {
          if (route.query.l) { 
            alert.value.push({
              class: "success",
              title: "SUCCESS",
              message: "Welcome back. Last login " + func.getDateTimeDiff(func.convDateTimeFormat(route.query.l), new Date()) + ", last active " + func.getDateTimeDiff(func.convDateTimeFormat(route.query.a), new Date()) + "."
            })
          }
        }

        if (localStorage.getItem('leftViewDrawer') === 'true') {
          showToolbox.value = true

        } else if (localStorage.getItem('leftViewDrawer') === 'false') { 
            showToolbox.value = false

        } else {
          showToolbox.value = true
          localStorage.setItem('leftViewDrawer', true)
        }

        getDocument(route.params.documentId)
        getProject()
        setTimeout(() => {
          iniScale()
        }, 500)

      })

      return { 
        alert, closeAlert, store, route, router, func, url, urlEncode, contentClass, showToolbox, toolboxBtnIcon, curPage, getTotalPage, 
        doc, docName, docStatus, updPageNum, toggleToolbox, getDocument, 
        projName, projPrivilege, showMoreSignee, docSignee, 
        uiRatio, pdfWidth, getUserId, zoomDefault, zoomIn, zoomOut, navigatePage, isPdfLoaded, pageCount, scale, page, pageCount, goToPage, randKey, editPage,
        browserWidth, docWidth
      }
  },
  /* mounted () {
    // Initiate tooltip
    Array.from(document.querySelectorAll('button[data-bs-toggle="tooltip"]')).forEach(tooltipNode => new Tooltip(tooltipNode))
    Array.from(document.querySelectorAll('span[data-bs-toggle="tooltip"]')).forEach(tooltipNode => new Tooltip(tooltipNode))
  } */
}
</script>
/* PENDING:
- select doc affect remark project name
- show selected color by default
*/

// NOTE: fontFamily,fontSize pass from parent to DragBox component thr props, other from component to parent thr getPosition
// npm i vue3-draggable-resizable
// https://www.npmjs.com/package/vue3-draggable-resizable
// npm i vue-pdf-embed
// https://www.npmjs.com/package/vue-pdf-embed
// npm i pdf-lib
// https://www.npmjs.com/package/pdf-lib
// npm i downloadjs

// test https://www.npmjs.com/package/vue3-date-time-picker
// https://vue3datepicker.com/installation/#global
// npm i vue3-date-time-picker

// References
// https://www.designcise.com/web/tutorial/how-to-apply-css-opacity-to-background-color-only
// scan signature https://www.youtube.com/watch?v=0ArXBk6vS5U

<style>
body {
  color: #6E777E;
  font-size: 14px;
  overflow: hidden;
}

.pdfBg {
  background-image: url("../../assets/bgTrans1.png");
  width: 100%;
}

.parent {
  width: 100%;
  position: absolute;
  top: 0px;
  left: 0px; 
  user-select: none;
}

.box {
  border-style: dashed;
  border-width: 3px;
  cursor: pointer;
  border-radius: 5px;
}

.box:hover {
  border-style: dashed;
  border-width: 3px;
  cursor: move;
}

.box:active {
  cursor: move;
  /* border: 2px dashed #e98f07; */
  border-style: dashed;
  border-width: 3px;
}

.boxSelected {
  border-width: 4px;
  border-style: solid;
}

.boxCal {
  padding-left: 8px;
  padding-right: 8px;
  width: 200px;
}

.toolboxBtnClose {
  position: fixed; 
  margin-top: 6px;
  width: 20px; 
  height: 45px; 
  padding: 11px 2px; 
  background-color: grey;
  border-top-right-radius: 8px; 
  border-bottom-right-radius: 8px; 
  z-index: 9; 
  opacity: 0.8; 
  cursor: pointer
}

.toolboxBtnOpen {
  position: fixed;
  margin-top: 6px;
  left: 251px; 
  width: 20px; 
  height: 45px; 
  padding: 11px 2px; 
  background-color: grey;
  border-top-right-radius: 8px; 
  border-bottom-right-radius: 8px; 
  z-index: 9; 
  opacity: 0.8; 
  cursor: pointer
}

.handle {
  cursor: move;
}

.card {
    background-color: var(--toolbar-bg);
    border: 1px solid var(--toolbar-border);
}

.card-header {
    padding: 0px;
    border-bottom: 0px;
    background-color: var(--toolbar-bg);
}

.card-header .btn {
    padding: 5px 10px;
    font-size: 14px;
    font-weight: 600;
}

.card-header .btn:focus {
    box-shadow: 0 0 0 0;
}

.card-body {
    padding: 5px 12px;
}

.border-bottom {
  border-bottom: 1px solid var(--toolbar-hr) !important;
}

.toolbarViewFloat {
  position: fixed; 
  z-index: 2; 
  top: 41px;
  width: 255px; 
  opacity: 0.8; 
}
</style>