<template>
    <span v-if="userIsEditor === true || userIsEditor === 'true'">
        <Popper class="popperDark" arrow hover content="Owner"><fa icon="square-pen" class="me-1"/></Popper>
        <span :class="userClass">{{userName}}</span>
    </span>
    <span v-else>
        <span :class="userClass">{{userName}}</span>
    </span>
</template>

<script>
import {ref, onMounted} from 'vue'
import Popper from 'vue3-popper'

export default {
    name: 'UserDisplayName',
    components: { Popper },
    props: [ 'displayName', 'class', 'isEditor' ],
    setup (props) {

        const userName = ref(props.displayName)
        const userClass = ref(props.class)
        const userIsEditor = ref(props.isEditor)

        onMounted(() => {
        })

        return {
            userName, userClass, userIsEditor,
        }
    }
}
</script>

<style>

</style>